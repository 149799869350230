/* eslint no-console:0 */
const exports = module.exports = {};
const environmentList = require('../../server/config/supportedEnvironmentList.json');
const userAgentParser = require('../../server/shared/userAgentUtils');

/**
 * @api {Javascript} BioserverEnvironment.detection detection
 * @apiVersion ${project.version}
 * @apiName detection
 * @apiGroup Environment
 *
 * @apiDescription Detect if current environment (OS/browser) is supported. If environment is not
 * supported, the response contains a list of supported browser according to the current OS (parameter
 * supportedBrowser)
 * @apiExample {javascript} Example usage
 *  // request if current environment (OS/browser) is supported
 * var env = BioserverEnvironment.detection();
 * if (!env.envDetected) { console.log("env detection failed with error:" + env.message); return }
 * var envOS = env.envDetected.os;
 * if (!envOs.isSupported) { console.log("env detection error: ", env.message , "Supported OS list", envOs.supportedList); return }
 * var envBrowser = env.envDetected.browser;
 * if (!envBrowser.isSupported) { console.log("env detection error: ", env.message , "Supported Browsers", envBrowser.supportedList); return }
 * @apiSuccessExample {Javascript} Result: OS & browser supported
 *
 {
   "envDetected": {
     "os": {
       "isSupported": true,
       "supportedList": [],
       "isMobile": false
     },
     "browser": {
       "isSupported": true,
       "supportedList": []
     }
   },
   "message": ""
 }
 *
 * @apiErrorExample {Javascript} Result: Browser not supported
 *
 {
   "envDetected": {
     "os": {
       "isSupported": true,
       "supportedList": [],
     },
     "browser": {
       "isSupported": false,
       "supportedList": [
         {
           "name": "Chrome",
           "minimumVersion": "56"
         },
         {
           "name": "Firefox",
           "minimumVersion": "50"
         },
         {
           "name": "Opera",
           "minimumVersion": "47"
         },
         {
           "name": "Edge",
           "minimumVersion": "17"
         }
       ]
     }
   },
   "message": "You seem to be using an unsupported browser."
 }

 *
 **/
exports.detection = function () {
    // no usage of device model or os version here so no need to retrieve highEntropyValues
    const ua = userAgentParser.parse(navigator.userAgent);
    const userOS = ua.os.name;
    const userBrowser = ua.browser.name;
    const userBrowserVersion = Number(ua.browser.major || 0);
    const browserAnalyzer = { isChromeEngine: userAgentParser.isChromeEngine(navigator), isChromeBrowser: userAgentParser.isChromeBrowser(ua) };

    console.log('User using ' + userBrowser + ' v' + userBrowserVersion + ' on ' + userOS);

    const result = {
        envDetected: {
            os: {
                isSupported: false,
                supportedList: []
            },
            browser: {
                isSupported: false,
                supportedList: []
            }
        },
        message: ''
    };

    if (!environmentList || !environmentList.length) {
        Object.assign(result, { envDetected: false, message: 'Environment detection failed' });
        return result;
    }

    const { foundOs, foundBrowser, supportedOsList, supportedBrowserList } = analyseEnvironment(userOS, userBrowser, userBrowserVersion, browserAnalyzer);

    if (!foundOs) {
        result.envDetected.os.isSupported = false;
        result.envDetected.os.supportedList = supportedOsList;
        result.message = 'You seem to be using an unsupported operating system.';
    } else if (!foundBrowser) {
        result.envDetected.os.isSupported = true;
        result.envDetected.browser.isSupported = false;
        // Filter out Chrome WebView as it is technical and not understood by users.
        result.envDetected.browser.supportedList = supportedBrowserList.filter(browser => browser.name !== 'Chrome WebView'); // NOSONAR supportedBrowserList cannot be undefined here
        result.message = 'You seem to be using an unsupported browser.';
    } else {
        result.envDetected.os.isSupported = true;
        result.envDetected.os.isMobile = foundOs.isMobile === 'true'; // NOSONAR foundOs cannot be undefined here
        result.envDetected.browser.isSupported = true;
    }
    console.log(result);
    return result;
};

function isBrowserSupported(userBrowser, userBrowserVersion, envBrowser) {
    return envBrowser.name === userBrowser && Number(envBrowser.minimumVersion) <= userBrowserVersion;
}

function analyseEnvironment(userOS, userBrowser, userBrowserVersion, browserAnalyzer) {
    let supportedOsList = [];
    let foundBrowser;
    let foundOs;
    let supportedBrowserList;
    environmentList.forEach(detectedEnv => {
        supportedOsList = supportedOsList.concat(detectedEnv.os.name.split(','));
        if (!foundBrowser && detectedEnv.os.name.split(',').indexOf(userOS) > -1) {
            foundOs = { name: detectedEnv.os.name, isMobile: detectedEnv.os.isMobile };
            const browserList = detectedEnv.browserList;
            for (let i = 0; i < browserList.length; i++) {
                const browserListElement = browserList[i];

                if (isBrowserSupported(userBrowser, userBrowserVersion, browserListElement)) {
                    if (!browserAnalyzer.isChromeBrowser || (browserAnalyzer.isChromeBrowser && !browserAnalyzer.isChromeEngine)) {
                        foundBrowser = browserListElement;
                        break;
                    }
                }
            }
            if (!foundBrowser) {
                supportedBrowserList = browserList;
            }
        }
    });
    return {
        foundOs,
        foundBrowser,
        supportedOsList,
        supportedBrowserList
    };
}
