// IMPORTANT NOTE: this file and all its dependencies are included in the WebSDK
// BEWARE of not adding sensitive code or library

const uaParser = require('ua-parser-js');

/**
 * Parse a user agent in order to detect browser used such as ua-parser-js library
 * but including HuaweiBrowser
 * @param {string} userAgent
 * @param {{deviceModel?: string, osVersion?: string|number, browserVersion?: string, engineVersion?: string, browserBrand?: string}?} props
 * @returns {object} userAgent parsed
 */
module.exports.parse = function (userAgent, { deviceModel, osVersion, browserVersion, engineVersion, browserBrand } = {}) {
    let ua;
    // Since chrome 110+, in order to have device vendor, the device model and os version has to be injected dynamically before parsing it
    if (deviceModel && osVersion) {
        ua = userAgent;
        // the following regex doesn't contain any risk since the number of white space is limited to 2 maximum after 10 and before K (false positive from sonar checks)
        userAgent = userAgent?.replace(/(Android 10\s{0,2};\s{0,2})K/, `Android ${osVersion} ; ${deviceModel}`); // NOSONAR
    }
    const parsed = uaParser(userAgent);
    const hiddenBrowserList = ['HuaweiBrowser'];
    const result = getHiddenBrowser(hiddenBrowserList, userAgent);
    if (result) {
        parsed.browser = { name: result.name, version: result.version, major: result.majorVersion };
    }
    // Inject original userAgent into 'ua' field
    if (ua) {
        parsed.ua = ua;
    }
    // Set proper browser & engine version if supplied
    if (browserVersion) {
        parsed.browser.version = browserVersion;
    }
    if (engineVersion) {
        parsed.engine.version = engineVersion;
    }
    // Patch for chromium browsers which cannot decode the real browser name (example Brave mobile),
    // We use the browserBrand value from device if supplied and if it is not the real 'Google Chrome'
    if (parsed.browser.name === 'Chrome' && browserBrand && browserBrand !== 'Google Chrome') {
        parsed.browser.name = browserBrand;
    }
    // Patch for Samsung Browser which is now called Samsung Internet, we keep old name for backward compatibility
    if (parsed.browser.name === 'Samsung Internet') {
        parsed.browser.name = 'Samsung Browser';
    }
    return parsed;
};

/**
 * Detect if the user agent is a firefox browser
 * @param userAgent
 * @returns {boolean}
 */
module.exports.isFirefox = function (userAgent) {
    const { browser } = userAgent;
    return Boolean(browser && browser.name && browser.name.toLowerCase().indexOf('firefox') > -1);
};

/**
 * Detect if the user agent is a chrome browser
 * @param userAgent
 * @returns {boolean}
 */
module.exports.isChromeBrowser = function (userAgent) {
    const { browser } = userAgent;
    return Boolean(browser && browser.name && browser.name.toLowerCase().indexOf('Chrome') > -1);
};

/**
 * Detect if the user agent has a chrome engine
 * @param navigator
 * @returns {boolean}
 */
module.exports.isChromeEngine = function (navigator) {
    return Boolean(navigator.userAgentData &&
        navigator.userAgentData.brands &&
        navigator.userAgentData.brands.some(b => b.brand === 'Google Chrome'));
};

/**
 * Retrieve in the userAgent hidden browsers listed in browsersList
 * @param browsersList
 * @param userAgent
 */
function getHiddenBrowser(browsersList, userAgent) {
    let result;
    if (userAgent === undefined) {
        return result;
    }

    const ua = userAgent ? (userAgent.ua ? userAgent.ua : userAgent) : '';
    browsersList.forEach(browser => {
        const pattern = browser.toLowerCase() + '/';
        const indexStarting = ua.toLowerCase().indexOf(pattern);
        if (indexStarting > -1) {
            const tmpUA = ua.substring(indexStarting, ua.length - 1);
            const tmpUA2 = tmpUA ? tmpUA.substring(pattern.length, tmpUA.length - 1) : undefined;
            const version = (tmpUA2 && tmpUA2.indexOf(' ') > 0) ? tmpUA2.substring(0, tmpUA2.indexOf(' ')) : undefined;
            const subVersion = version ? version.split('.') : undefined;
            const majorVersion = (subVersion && subVersion.length > 0) ? subVersion[0] : undefined;
            result = { name: browser, version: version, majorVersion: majorVersion };
        }
    });
    return result;
}
// for unit tests
module.exports.getHiddenBrowser = getHiddenBrowser;

/**
 * Get the device type from Platform
 * @param {string} browserPlatform represents the platform on which the browser is executing.
 * @param {string} userAgent user agent.
 * @returns {string|undefined}
 *   undefined: if device type is not found
 *   DEVICE_PLATFORM:  OS name
 */
module.exports.getDevicePlatform = function (browserPlatform, userAgent) {
    let os; // undefined if device type is not found
    if (!browserPlatform) {
        return os; // return undefined if navigator.platform is unsupported
    }

    // Do not check Platform for Windows Phone (is already not supported)
    // as Nokia Lumia 2520 with platform Win32 and MS Surface Tablet Pro with platform Win64
    if (userAgent.match(/Windows Phone/i)) {
        return os; // return undefined for windows phone
    }
    // Do not check Platform for Macintosh and MacIntel
    // for the case of the new iPad
    if (/\b(MacPPC|Mac68K)\b/i.test(browserPlatform)) {
        os = DEVICE_PLATFORM.MAC;
    } else if (/\b(iPhone|iPad|iPod)\b/i.test(browserPlatform)) {
        os = DEVICE_PLATFORM.IOS;
    } else if (/\b(Win32|Win64|Windows|WinCE)\b/i.test(browserPlatform)) {
        os = DEVICE_PLATFORM.WINDOWS;
    } else if (/\b(Linux)\b/i.test(browserPlatform) && /(Android)/i.test(userAgent)) { // add check of user agent to difference android and linux distribution)
        os = DEVICE_PLATFORM.ANDROID;
    }
    return os;
};

const DEVICE_PLATFORM = {
    ANDROID: 'Android',
    IOS: 'iOS',
    MAC: 'Mac',
    WINDOWS: 'Windows'
};

module.exports.DEVICE_PLATFORM = DEVICE_PLATFORM;
